import "slick-carousel";

document.addEventListener("DOMContentLoaded", function () {
  // instagram画像のスライド
  $(".js-slick-instagram-mag").slick({
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    cssEase: "linear",
    dots: false,
    fade: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    zIndex: 1,
  });
});
