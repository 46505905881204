function handleLink(event) {
  event.preventDefault();
  const link = event.currentTarget;
  let url;
  switch (link.getAttribute("data-url-type")) {
    case "hotellist_spec":
      url = hotellistSpecUrl(link);
      break;
    case "hotellist_location":
      url = hotellistLocationUrl(link);
      break;
    case "hotellist":
      url = hotellistUrl();
      break;
    case "hotels_plans":
      url = hotelsPlansUrl(link);
      break;
    case "aggregated_hotels_plans":
      url = aggregatedHotelsPlansUrl(link);
      break;
    case "hotellist_result":
      url = hotellistResultUrl(link);
      break;
  }
  if (url?.trim()) {
    openUrl(url, link.getAttribute("data-target"));
  }
}

function hotellistSpecUrl(link) {
  const location_type = link.getAttribute("data-location-type");
  const location_id = link.getAttribute("data-location-id");
  const spec_id = link.getAttribute("data-spec-id");
  return `/hotellist-${location_type}-${location_id}-${spec_id}`;
}

function hotellistLocationUrl(link) {
  const location_type = link.getAttribute("data-location-type");
  const location_id = link.getAttribute("data-location-id");
  return `/hotellist-${location_type}-${location_id}`;
}

function hotellistUrl() {
  return "/hotellist";
}

function hotelsPlansUrl(link) {
  const hotel_id = link.getAttribute("data-hotel-id");
  const query = link.getAttribute("data-query");
  return `/hotels/${hotel_id}/plans${query ? `?${query}` : ""}`;
}

function aggregatedHotelsPlansUrl(link) {
  const hotel_id = link.getAttribute("data-hotel-id");
  const query = link.getAttribute("data-query");
  return `/hotels-${hotel_id}/plans${query ? `?${query}` : ""}`;
}

function hotellistResultUrl(link) {
  const query = link.getAttribute("data-query");
  return `/hotellist/result?${query}`;
}

function openUrl(url, target) {
  target === "true" ? window.open(url, "_blank", "noopener") : (window.location.href = url);
}

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".js-link").forEach((link) => {
    link.addEventListener("click", handleLink);
    link.style.cursor = "pointer";
  });
});
export { handleLink };
