import lozad from "lozad";
import { handleLink } from "javascripts/webpage/link";
import { swipeForWindows } from "javascripts/webpage/swipe-for-windows";

document.addEventListener("DOMContentLoaded", () => {
  function pollVacancyPickupHotels() {
    const loader = vacancyPickupHotels.querySelector(".js-loader-for-vacancy-pickup-hotels");
    fetch(vacancyPickupHotels.dataset.pollingUrl)
      .then((response) => {
        if (!response.ok) {
          throw "ErrorException";
        }
        return response.json();
      })
      .then((data) => {
        if ("html" in data) {
          loader.style.display = "none";
          vacancyPickupHotels.innerHTML = data.html;
          if (!data.html) {
            return;
          }
          const addedElements = vacancyPickupHotels.querySelectorAll(".js-lazy:not([data-loaded])");
          const newObserver = lozad(addedElements, {
            rootMargin: "200% 400%",
          });
          newObserver.observe();
          vacancyPickupHotels.querySelectorAll(".js-link").forEach((link) => {
            link.addEventListener("click", handleLink);
          });
          swipeForWindows();
        } else {
          // プラン取得のポーリング間隔(1000)と被らないように少しずらしている
          setTimeout(pollVacancyPickupHotels, 1100);
        }
      })
      .catch(() => {
        loader.style.display = "none";
      });
  }
  const vacancyPickupHotels = document.querySelector(".js-vacancy-pickup-hotels");
  if (vacancyPickupHotels) {
    vacancyPickupHotels.innerHTML += '<div class="p-hotels-shared-loader js-loader-for-vacancy-pickup-hotels">おすすめホテルを取得中です</div>';
    pollVacancyPickupHotels();
  }
});
