document.addEventListener("DOMContentLoaded", () => {
  if (!document.querySelector("#js-aggregated_hotels-show")) {
    return;
  }
  // サイドバーの量（高さ）に応じて、上または下付きにする
  const stickySidebar = document.querySelector(".js-sticky-sidebar");
  if (stickySidebar) {
    const sidebar = document.querySelector(".l-side-column__contents");
    const sidebarHeight = sidebar.clientHeight;
    if (sidebarHeight > 842) {
      stickySidebar.classList.remove("l-side-column--shortage");
      sidebar.classList.remove("l-side-column__contents--top");
    }
  }
});
