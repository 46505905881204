document.addEventListener("DOMContentLoaded", function () {
  const navHeader = document.querySelector(".js-nav");
  if (navHeader == null) return;

  const observer = new IntersectionObserver(
    (entries) => {
      if (entries.some((e) => e.isIntersecting)) {
        navHeader.classList.add("is-active");
      } else {
        navHeader.classList.remove("is-active");
      }
    },
    { rootMargin: "0% 0% -100% 0%" },
  );
  observer.observe(document.querySelector(".js-nav-viewport"));
});
